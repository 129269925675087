import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    products: [],
    filter: {
      tyProduct: {
        ismatch: false,
        product_id: '',
      },

      page: 0,
      limit: 25,
    },
  },
  getters: {
    _products(state) {
      return state.products
    },
    _filter(state) {
      return state.filter
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products
    },
  },
  actions: {
    getProducts(context) {
      context.commit('setProducts', [])
      axios.post('/trendyol/product', context.state.filter)
        .then(res => {
          if (res.data.status == 'success') {
            context.commit('setProducts', res.data.content.data)
          }
        })
    },
    updateProduct(context, payload) {
      axios.put('/trendyol/product/' + payload.id, payload)
    },
  },
}
